import React from "react";
import styled from "styled-components";
import ImageLoader from "../imageLoader";
import { Colors } from "../../constants";
import { Button } from "antd";
import { Link } from "react-router-dom";

const SectionCntr = styled.div`
    display: flex;
    flex-flow: ${props => (props.reverse ? "row-reverse nowrap" : "row nowrap")};
    justify-content: space-between;
    @media (max-width: 798px) {
        flex-flow: row wrap;
    }
    & > div {
        width: 50%;
        @media (max-width: 798px) {
            width: 100%;
        }
    }
`;

const ImgCntr = styled.div`
    width: 50vw;
    height: calc(50vw * 0.5625);
    @media (max-width: 798px) {
        width: 100vw;
        height: calc(100vw * 0.5625);
    }
`;

const OrangeOverlap = styled.div`
    background-color: ${Colors.primaryOrange};
    height: 48px;
    width: 42vw;
    margin: ${props => (props.reverse ? "0 0 0 auto" : "0 auto 0 0")};
    @media (max-width: 798px) {
        width: 84vw;
    }
`;

const Info = styled.div`
    width: 34vw;
    margin: 0 auto;
    padding: 6vw 0;
    @media (max-width: 798px) {
        width: 84vw;
    }
    & > h3 {
        font-size: 38px;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 2px;
        line-height: 46px;
    }
    & > p {
        font-size: 15px;
        letter-spacing: 1.5px;
    }
`;

const InfoBtn = styled(Button)`
    color: ${Colors.darkGray};
    border-color: ${Colors.primaryOrange};
    background-color: transparent;
    &:hover,
    :focus {
        background-color: transparent;
        color: ${Colors.lightPrimaryOrange};
        border-color: ${Colors.primaryOrange};
    }
`;

const InfoSection = ({ header, description, side, btnText, btnLink, img, id }) => {
    return (
        <SectionCntr reverse={side === "left"} id={id}>
            <div>
                <OrangeOverlap reverse={side === "left"} />
                <ImgCntr>
                    <ImageLoader placeholder={`${img}_low.jpg`} image={`${img}_reg.jpg`} width={"100%"} height={"100%"} />
                </ImgCntr>
                <OrangeOverlap reverse={side === "left"} />
            </div>
            <div>
                <Info>
                    <h3>{header}</h3>
                    <p>{description}</p>
                    <div style={{ margin: "30px auto 0 auto", textAlign: "center" }}>
                        <Link to={btnLink}>
                            <InfoBtn size={"large"}>{btnText}</InfoBtn>
                        </Link>
                    </div>
                </Info>
            </div>
        </SectionCntr>
    );
};

export default InfoSection;
