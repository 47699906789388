import React from "react"
import { Spin } from "antd"
import styled from "styled-components"
import { Colors } from "../constants"

const LoadCntr = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 30vh 0;
    text-align: center;
    height: 90vh;
    & .ant-spin {
        font-size: 28px;
        color: ${Colors.darkGray};
        & .ant-spin-dot {
            width: 48px;
            height: 48px;
            & .ant-spin-dot-item {
                background-color: ${Colors.primaryOrange};
                width: 24px;
                height: 24px;
            }
        }
    }
`

const Loading = () => (
    <LoadCntr>
        <Spin tip={"Loading..."} size={"large"} />
    </LoadCntr>
)

export default Loading
