import React from "react"
import { Row, Col, Button } from "antd"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { Colors } from "../../constants"
import Text from "../translation"
import TXT from "./translation"

const Div = styled.div`
    background-color: #f3f4f5;
    padding: 48px;
`
const P = styled.p`
    font-size: 20px;
`

const ButtonCntr = styled.div`
    margin: 18px auto;
    text-align: center;
`

const DefaultButton = styled(Button)`
    color: ${Colors.darkGray};
    border-color: ${Colors.primaryOrange};
    background-color: transparent;
    &:hover,
    :focus {
        background-color: transparent;
        color: ${Colors.lightPrimaryOrange};
        border-color: ${Colors.primaryOrange};
    }
`

const ImageCntr = styled.div`
    width: 100%;
    margin: 0 auto 32px auto;
    text-align: center;
    & > img {
        width: 180px;
        margin: 0 auto;
    }
`

const LandingPageParagraph = () => {
    return (
        <Div>
            <ImageCntr>
                <img src={"/ezAssets/EZ_logo_250.png"} />
            </ImageCntr>
            <Row>
                <Col span={18} offset={3}>
                    <P>
                        <Text textObject={TXT.about} />
                    </P>
                </Col>
            </Row>

            <ButtonCntr>
                <Link to={"/events"}>
                    <DefaultButton size={"large"}>
                        <Text textObject={TXT.playBtn} />
                    </DefaultButton>
                </Link>
            </ButtonCntr>
        </Div>
    )
}

export default LandingPageParagraph
