import React, { useContext, useEffect, useState } from "react"
import { Link, NavLink, useLocation } from "react-router-dom"
import AuthContext from "../authContext"
import { gql, useLazyQuery, useMutation } from "@apollo/client"
import { Button, Dropdown, Menu, Icon, Avatar, notification } from "antd"
import styled from "styled-components"
import { Colors } from "../constants"
import Text from "./translation"
import TXT from "./navTranslations"

export const ME = gql`
    query Me {
        me {
            id
            firstName
            lastName
            fullName
            email
            userType
            subscriptionActive
            connectAccount
            cancelling
            isAdmin
            isDataEntry
            notifications {
                id
                title
                message
                buttonLink
                buttonName
            }
        }
    }
`

const NOTIFICATION_READ = gql`
    mutation MarkNotificationRead($notificationId: Int!) {
        markNotificationRead(notificationId: $notificationId) {
            ok
        }
    }
`

const CLEAR_ALL_NOTIFICATIONS = gql`
    mutation ClearAllNotifications {
        clearAllNotifications {
            ok
        }
    }
`

const NavBar = styled.div`
    width: 100%;
    background-color: ${props => (props.home ? "transparent" : Colors.white)};
    padding: 6px 28px;
    color: ${props => (props.home ? Colors.white : Colors.darkGray)};
    & > div {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        max-width: 1400px;
        margin: 0 auto;
    }
`

const Logo = styled.div`
    display: inline-block;
    width: 120px;
    color: ${Colors.darkGray};
    & p {
        color: ${props => (props.home ? Colors.white : Colors.darkGray)};
        margin: 0 auto;
    }
    & span {
        color: ${Colors.primaryOrange};
    }
    text-align: center;
    & > img {
        width: 50px;
    }
`

const MenuIcon = styled(Icon)`
    margin: 22px 12px 12px 12px;

    font-size: 22px;
    height: 22px;
`

const PrimaryButton = styled(Button)`
    background-color: ${Colors.primaryOrange};
    border-color: ${Colors.primaryOrange};
    &:hover {
        background-color: ${Colors.lightPrimaryOrange};
        border-color: ${Colors.primaryOrange};
    }
`

const DefaultButton = styled(Button)`
    border-color: ${Colors.primaryOrange};
    color: ${Colors.white};
    background-color: transparent;
    &:hover {
        background-color: transparent;
        border-color: ${Colors.lightPrimaryOrange};
        color: ${Colors.lightPrimaryOrange};
    }
`

const UserAvatar = styled.div`
    position: relative;
    margin-top: 8px;
    text-align: center;
    width: 100px;
    cursor: pointer;
    & p {
        margin: 0 auto;
    }
`

const LoginCntr = styled.div`
    margin: 18px;
    @media (max-width: 580px) {
        margin: 18px 4px;
    }
`

const LoginLink = styled(NavLink)`
    color: ${Colors.white};
    margin: 0 28px;
`

const NotificationIcon = styled(Icon)`
    color: ${Colors.primaryOrange};
    font-size: 15px;
    position: absolute;
    top: -4px;
    right: 28px;
    z-index: 1;
`

const Nav = () => {
    const { token, user, setUser, isAuth, setIsAuth, logOut, loggingOut, setLoggingOut } = useContext(AuthContext)
    const [updateUser, setUpdateUser] = useState(false)

    const [getUser, { data: userData, error: userError, loading: loadingUser }] = useLazyQuery(ME, {
        fetchPolicy: "network-only"
    })

    const [readNotification] = useMutation(NOTIFICATION_READ)
    const [clearAllNotifications] = useMutation(CLEAR_ALL_NOTIFICATIONS)

    const markNotificationRead = id => {
        setUpdateUser(true)
        readNotification({
            variables: {
                notificationId: id
            }
        }).then(() => getUser())
    }

    const clearNotifications = () => {
        setUpdateUser(true)
        clearAllNotifications().then(() => getUser())
    }

    const handleLogOut = () => {
        setLoggingOut(true)
        logOut().then(() => setLoggingOut(false))
    }

    useEffect(() => {
        if (!loggingOut && token && !user && !userData) {
            getUser()
        } else if (!loggingOut && token) {
            getUser()
            setIsAuth(true)
        }
    }, [token])

    useEffect(() => {
        if (!loggingOut && userData && userData.me && (!user || updateUser)) {
            setUpdateUser(false)
            let user = { ...userData.me }
            if (!user.subscriptionActive) {
                user.notifications = [
                    {
                        id: null,
                        title: "Your subscription is not active",
                        message:
                            "Your EZ Horse Entry subscription is not active. Please update your payment information in the settings to enjoy all features of EZ Horse Entry.",
                        buttonLink: "/settings/subscription",
                        buttonName: "Go to Settings ->"
                    },
                    ...user.notifications
                ]
            }
            setUser(user)
        }
    }, [userData, user])

    useEffect(() => {
        if (userError && userError.graphQLErrors[0].message === "UNAUTHENTICATED") {
            handleLogOut()
        }
    }, [userError])

    const loc = useLocation()
    const isHome = loc.pathname === "/"

    useEffect(() => {
        if (isAuth) {
            getUser()
        }
    }, [loc])

    return isHome ? (
        <HomeNav user={user} isAuth={isAuth} logOut={handleLogOut} />
    ) : (
        <AppNav
            user={user}
            isAuth={isAuth}
            logOut={handleLogOut}
            markNotificationRead={markNotificationRead}
            clearNotifications={clearNotifications}
        />
    )
}

export default Nav

const HomeNav = ({ isAuth, user, logOut }) => {
    return (
        <NavBar home>
            <div>
                <NavLink to={isAuth && user ? "/dashboard" : "/"}>
                    <Logo home>
                        <img src={"/ezAssets/EZ_logo_250.png"} alt={"ez horse logo"} />
                        <p>
                            <span>EZ</span> Horse Entry
                        </p>
                    </Logo>
                </NavLink>
                {isAuth ? (
                    <Dropdown overlay={loggedInMenu(user, logOut)}>
                        <MenuIcon type={"menu"} />
                    </Dropdown>
                ) : (
                    <LoginCntr>
                        <LoginLink to={"/login"}>
                            <Text textObject={TXT.login} />
                        </LoginLink>
                        <NavLink to={"/login?new=t"}>
                            <PrimaryButton type={"primary"}>
                                <Text textObject={TXT.signup} />
                            </PrimaryButton>
                        </NavLink>
                    </LoginCntr>
                )}
            </div>
        </NavBar>
    )
}

const AppNav = ({ user, logOut, isAuth, markNotificationRead, clearNotifications }) => {
    const menu = isAuth ? (
        loggedInMenu(user, logOut)
    ) : (
        <Menu style={{ width: "220px" }}>
            <Menu.Item key={1}>
                <NavLink to={"/"}>
                    <Text textObject={TXT.home} />
                </NavLink>
            </Menu.Item>
            <Menu.Item key={2}>
                <NavLink to={"/events"}>
                    <Text textObject={TXT.browseEvents} />
                </NavLink>
            </Menu.Item>
            <Menu.Item key={3}>
                <NavLink to={"/login"}>
                    <Text textObject={TXT.login} />
                </NavLink>
            </Menu.Item>
            <Menu.Item key={4}>
                <NavLink to={"/login?new=t"}>
                    <Text textObject={TXT.signup} />
                </NavLink>
            </Menu.Item>
        </Menu>
    )

    return (
        <NavBar>
            <div>
                <Dropdown overlay={menu}>
                    <MenuIcon type={"menu"} />
                </Dropdown>
                <NavLink to={"/"}>
                    <Logo>
                        <img src={"/ezAssets/EZ_logo_250.png"} alt={"ez horse logo"} />
                        <p>
                            <span>EZ</span> Horse Entry
                        </p>
                    </Logo>
                </NavLink>
                {isAuth && (
                    <div>
                        <Dropdown
                            overlay={avatarMenu(
                                logOut,
                                user ? user.notifications : [],
                                markNotificationRead,
                                clearNotifications,
                                user?.isAdmin || user?.isDataEntry
                            )}
                        >
                            <UserAvatar>
                                {user && user.notifications.length ? (
                                    <NotificationIcon type={"bell"} theme={"filled"} />
                                ) : null}
                                <Avatar icon={"user"} />
                                <p>
                                    <Text textObject={TXT.welcome} /> {user?.firstName}
                                </p>
                            </UserAvatar>
                        </Dropdown>
                    </div>
                )}
            </div>
        </NavBar>
    )
}

const loggedInMenu = (user, logOut) => (
    <Menu style={{ width: "220px" }}>
        <Menu.Item key={1}>
            <NavLink to={"/"}>
                <Text textObject={TXT.home} />
            </NavLink>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key={2}>
            <NavLink to={"/events"}>
                <Text textObject={TXT.browseEvents} />
            </NavLink>
        </Menu.Item>
        <Menu.Divider />
        {user && user.userType === "EVENT_OWNER" && (
            <Menu.Item key={3}>
                <NavLink to={"/myEvents"}>
                    <Text textObject={TXT.myEvents} />
                </NavLink>
            </Menu.Item>
        )}
        {user && user.userType === "STANDARD" && (
            <Menu.Item key={4}>
                <NavLink to={"/stable"}>
                    <Text textObject={TXT.myStable} />
                </NavLink>
            </Menu.Item>
        )}
        <Menu.Divider />
        {user && user.userType === "EVENT_OWNER" && (
            <Menu.Item key={5}>
                <NavLink to={"/createEvent"}>
                    <Text textObject={TXT.createEvent} />
                </NavLink>
            </Menu.Item>
        )}
        {user && user.userType === "STANDARD" && (
            <Menu.Item key={5}>
                <NavLink to={"/teams"}>
                    <Text textObject={TXT.teams} />
                </NavLink>
            </Menu.Item>
        )}
        {user && user.userType === "STANDARD" && <Menu.Divider />}
        {user && user.userType === "STANDARD" && (
            <Menu.Item key={7}>
                <NavLink to={"/finance"}>
                    <Text textObject={TXT.financial} />
                </NavLink>
            </Menu.Item>
        )}
        <Menu.Divider />
        <Menu.Item key={6} onClick={() => logOut()}>
            <span>
                <Text textObject={TXT.logOut} />
            </span>
        </Menu.Item>
    </Menu>
)

const openNotification = (notif, markNotificationRead) => {
    const config = {
        message: notif.title,
        description: notif.message,
        duration: 0,
        closeIcon: (
            <span style={{ color: Colors.lightPrimaryOrange }}>
                <Text textObject={TXT.dismiss} />
            </span>
        ),
        btn: notif?.btn ? <notif.btn /> : null,
        style: { padding: "36px 24px 24px 24px" },
        onClose: () => (notif.id ? markNotificationRead(notif.id) : null)
    }
    notification.open(config)
}

const { SubMenu } = Menu

const avatarMenu = (logOut, notifications, markNotificationRead, clearNotifications, isAdmin) => (
    <Menu
        style={{ width: notifications.length > 0 ? "300px" : "200px" }}
        mode={"inline"}
        openKeys={notifications.length ? ["notifications"] : []}
        inlineIndent={12}
    >
        <SubMenu
            key={"notifications"}
            mode={"inline"}
            title={
                <span>
                    <Icon type={"bell"} />
                    <span>
                        <Text textObject={TXT.notifications} /> ({notifications.length})
                    </span>
                </span>
            }
        >
            {notifications.length > 2 ? (
                <Menu.Item key={"clear"} onClick={() => clearNotifications()}>
                    <Icon type={"close"} /> <Text textObject={TXT.clearNotifications} />
                </Menu.Item>
            ) : null}
            {notifications.map((noti, idx) => (
                <Menu.Item key={idx} onClick={() => openNotification(noti, markNotificationRead)}>
                    {noti.title}
                </Menu.Item>
            ))}
        </SubMenu>
        {isAdmin && (
            <Menu.Item key={"admin"}>
                <Link to={"/admin-dashboard"}>
                    <Icon type={"control"} />
                    <span style={{ marginLeft: "6px" }}>Admin Dashboard</span>
                </Link>
            </Menu.Item>
        )}
        <Menu.Item key={"setting"}>
            <Link to={"/settings"}>
                <Icon type={"setting"} />
                <span style={{ marginLeft: "6px" }}>
                    <Text textObject={TXT.settings} />
                </span>
            </Link>
        </Menu.Item>
        <Menu.Item key={"logout"} onClick={() => logOut()}>
            <span>
                <Icon type={"logout"} />
                <span>
                    <Text textObject={TXT.logOut} />
                </span>
            </span>
        </Menu.Item>
    </Menu>
)
