import React, { useContext, useState } from "react"
import ImageLoader from "../imageLoader"
import LandingPageParagraph from "./landingPageParagraph"
import { Button, Input, Carousel } from "antd"
import styled from "styled-components"
import { Colors } from "../../constants"
import { Link, Redirect } from "react-router-dom"
import AuthContext from "../../authContext"
import InfoSection from "./infoSection"
import { HashLink } from "react-router-hash-link"
import Text from "../translation"
import TXT from "./translation"
import LangContext from "../../langContext"

const SearchCtnr = styled.div`
    width: 100%;
    min-height: 75vh;
    text-align: center;
`

const SearchBar = styled(Input.Search)`
    width: 50%;
    margin: 5vh auto 0 auto;
    & input {
        padding: 14px;
        height: 58px;
    }
    & button {
        height: 58px;
        background-color: transparent;
        border: none;
        border-left: 2px solid ${Colors.lightPrimaryOrange};
        color: ${Colors.darkGray};
        &:hover {
            background-color: ${Colors.primaryOrange};
            border-color: ${Colors.primaryOrange};
        }
    }
    @media (max-width: 700px) {
    width: 90%;
    //margin: 22px auto 0 auto;
    & input {
        height: 44px;
    }
    & button {
        height: 44px;
    }
`

const HeroTitle = styled.div`
    width: 100%;
    margin: 10vh auto 2vh auto;
    text-transform: uppercase;
    & > h2 {
        font-size: 18px;
        color: ${Colors.white};
        font-weight: normal;
        margin: 0 auto;
    }
    & > h1 {
        letter-spacing: 1px;
        margin: 0 auto;
        font-family: "Rozha One", serif;
        font-size: 48px;
        line-height: 42px;
        color: ${Colors.white};
    }
    @media (max-width: 580px) {
        margin: 8vh auto 2vh auto;
        & > h2 {
            font-size: 16px;
        }
        & > h1 {
            font-size: 36px;
        }
    }
    @media (max-width: 400px) {
        & > h2 {
            font-size: 14px;
        }
        & > h1 {
            font-size: 32px;
        }
    }
`

const HeaderButton = styled(Button)`
    margin-top: 28px;
    color: ${Colors.white};
    border-color: ${Colors.primaryOrange};
    background-color: transparent;
    &:hover,
    :focus {
        background-color: transparent;
        color: ${Colors.lightPrimaryOrange};
        border-color: ${Colors.primaryOrange};
    }
    @media (max-width: 580px) {
        font-size: 15px;
    }
`

const HeaderButtonPrimary = styled(Button)`
    margin: 3vh auto 3vh auto;
    color: ${Colors.white};
    border-color: ${Colors.primaryOrange};
    background-color: ${Colors.primaryOrange};
    &:hover,
    :focus {
        background-color: ${Colors.lightPrimaryOrange};
        border-color: ${Colors.primaryOrange};
    }
    @media (max-width: 580px) {
        font-size: 15px;
    }
`

const GroupSection = styled.div`
    width: 100vw;
    position: relative;
    height: 80vh;
    margin: 0 auto;
    text-align: center;
    padding-top: 36vh;
`

const MuchMoreSection = styled.div`
    width: 100%;
    max-width: 1400px;
    text-align: center;
    padding: 60px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 0 auto;
    & > h3 {
        font-size: 38px;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 2px;
        line-height: 46px;
        width: 100%;
    }
    & > ul {
        padding: 32px;
        text-align: left;
        width: 45%;
        font-size: 15px;
        letter-spacing: 1.5px;
        @media (max-width: 798px) {
            width: 100%;
            padding: 0 32px;
            margin-bottom: 0;
        }
        & > li {
            padding: 10px;
        }
    }
`

const CarouselCntr = styled(Carousel)`
    width: 90%;
    max-width: 600px;
    margin: 10vh auto 0 auto;
    @media (max-width: 580px) {
        margin: 6vh auto 0 auto;
    }
`

const Slide = styled.div`
    text-align: center;
    padding-bottom: 28px;
    & h2 {
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 0 auto;
        font-size: 32px;
        line-height: 38px;
        color: ${Colors.white};
        font-weight: bold;
    }
    & p {
        font-size: 18px;
        color: ${Colors.white};
        font-weight: normal;
        margin: 10px auto;
    }
    @media (max-width: 580px) {
        padding-bottom: 18px;
        & h2 {
            font-size: 26px;
            line-height: 28px;
        }
        & p {
            font-size: 16px;
        }
    }
    @media (max-width: 400px) {
        padding-bottom: 16px;
        & h2 {
            font-size: 22px;
            line-height: 24px;
        }
        & p {
            font-size: 13px;
        }
    }
`

const SliderBtn = styled(Button)`
    color: ${Colors.white};
    border-color: ${Colors.primaryOrange};
    background-color: transparent;
    margin: 12px auto;
    &:hover,
    :focus {
        background-color: transparent;
        color: ${Colors.lightPrimaryOrange};
        border-color: ${Colors.primaryOrange};
    }
`

const LandingPage = () => {
    const { isAuth } = useContext(AuthContext)
    const { lang } = useContext(LangContext)

    const [goSearch, setGoSearch] = useState("")
    const handleSearch = value => {
        setGoSearch(value)
    }

    return (
        <div>
            {goSearch && <Redirect to={`/events?q=${goSearch}`} />}
            <SearchCtnr>
                <ImageLoader
                    placeholder={"/ezAssets/landing_header_low.jpg"}
                    image={"/ezAssets/landing_header_high.jpg"}
                    width={"100vw"}
                    height={"94vh"}
                    position={"absolute"}
                    top={"0"}
                    zIndex={"-1"}
                />
                <CarouselCntr autoplay autoplaySpeed={4000}>
                    <Slide>
                        <h2>
                            <Text textObject={TXT.carousel.searchHdr} />
                        </h2>
                        <p>
                            <Text textObject={TXT.carousel.searchMsg} />
                        </p>
                        <HashLink to={"#search"} smooth>
                            <SliderBtn>
                                <Text textObject={TXT.carousel.learnBtn} />
                            </SliderBtn>
                        </HashLink>
                    </Slide>
                    <Slide>
                        <h2>
                            <Text textObject={TXT.carousel.stableHdr} />
                        </h2>
                        <p>
                            <Text textObject={TXT.carousel.stableMsg} />
                        </p>
                        <HashLink to={"#stable"} smooth>
                            <SliderBtn>
                                <Text textObject={TXT.carousel.learnBtn} />
                            </SliderBtn>
                        </HashLink>
                    </Slide>
                    <Slide>
                        <h2>
                            <Text textObject={TXT.carousel.teamHdr} />
                        </h2>
                        <p>
                            <Text textObject={TXT.carousel.teamsMsg} />
                        </p>
                        <HashLink to={"#team"} smooth>
                            <SliderBtn>
                                <Text textObject={TXT.carousel.learnBtn} />
                            </SliderBtn>
                        </HashLink>
                    </Slide>
                    <Slide>
                        <h2>
                            <Text textObject={TXT.carousel.payHdr} />
                        </h2>
                        <p>
                            <Text textObject={TXT.carousel.payMsg} />
                        </p>
                        <HashLink to={"#more"} smooth>
                            <SliderBtn>
                                <Text textObject={TXT.carousel.learnBtn} />
                            </SliderBtn>
                        </HashLink>
                    </Slide>
                    <Slide>
                        <h2>
                            <Text textObject={TXT.carousel.moreHdr} />
                        </h2>
                        <p>
                            <Text textObject={TXT.carousel.moreMsg} />
                        </p>
                        <Link to={"/login?new=t"}>
                            <SliderBtn>
                                <Text textObject={TXT.carousel.signupBtn} />
                            </SliderBtn>
                        </Link>
                    </Slide>
                </CarouselCntr>
                <SearchBar
                    size={"large"}
                    placeholder={TXT.searchTxt[lang]}
                    enterButton={TXT.searchBrn[lang]}
                    onSearch={handleSearch}
                />
                <HeroTitle>
                    <h2>
                        <Text textObject={TXT.heroTitle} />
                    </h2>
                    <h1>ez horse entry</h1>
                    <Link to={"/events"}>
                        <HeaderButtonPrimary size={"large"}>
                            <Text textObject={TXT.browseBtnCaps} />
                        </HeaderButtonPrimary>
                    </Link>
                </HeroTitle>
            </SearchCtnr>
            <LandingPageParagraph />
            <InfoSection
                id={"search"}
                header={<Text textObject={TXT.info.searchHdr} />}
                description={<Text textObject={TXT.info.searchDesc} />}
                side={"right"}
                btnText={<Text textObject={TXT.info.searchbtn} />}
                btnLink={"/events"}
                img={"/ezAssets/landingPage/market_search"}
            />
            <InfoSection
                id={"stable"}
                header={<Text textObject={TXT.info.stableHdr} />}
                description={<Text textObject={TXT.info.stableDesc} />}
                side={"left"}
                btnText={<Text textObject={TXT.info.signupBtn} />}
                btnLink={"/login?new=t"}
                img={"/ezAssets/landingPage/market_stable"}
            />
            <InfoSection
                id={"team"}
                header={<Text textObject={TXT.info.teamHdr} />}
                description={<Text textObject={TXT.info.teamDesc} />}
                side={"right"}
                btnText={<Text textObject={TXT.info.signupBtn} />}
                btnLink={"/login?new=t"}
                img={"/ezAssets/landingPage/market_team"}
            />
            <MuchMoreSection id={"more"}>
                <h3>
                    <Text textObject={TXT.info.muchHdr} />
                </h3>
                <ul>
                    <li>
                        <Text textObject={TXT.info.payment} />
                    </li>
                    <li>
                        <Text textObject={TXT.info.finances} />
                    </li>
                    <li>
                        <Text textObject={TXT.info.reports} />
                    </li>
                </ul>
                <ul>
                    <li>
                        <Text textObject={TXT.info.events} />
                    </li>
                    <li>
                        <Text textObject={TXT.info.documents} />
                    </li>
                    <li>
                        <Text textObject={TXT.info.nominations} />
                    </li>
                </ul>
                <div>
                    <Link to={"/login?new=t"}>
                        <HeaderButtonPrimary size={"large"}>
                            <Text textObject={TXT.info.signupTodayBtn} />
                        </HeaderButtonPrimary>
                    </Link>
                </div>
            </MuchMoreSection>
        </div>
    )
}

export default LandingPage
