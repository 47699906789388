import React, { useContext } from "react";
import LangContext from "../../langContext";

const Text = ({ textObject }) => {
    const { lang } = useContext(LangContext);
    if (!textObject) return <></>;
    if (!textObject[lang]) return <>{textObject.en}</> || <></>;
    return <>{textObject[lang]}</>;
};

export default Text;
