import { ApolloClient, HttpLink, ApolloLink, InMemoryCache, from } from "@apollo/client";
import { onError } from "@apollo/link-error";

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_URL + "/graphql"
});

const authMiddleware = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem("token");
    operation.setContext({
        headers: {
            authorization: token ? `Bearer ${token}` : ""
        }
    });
    return forward(operation);
});

const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
        graphQLErrors.map(e => {
            if (e.message === "UNAUTHENTICATED") {
                localStorage.removeItem("token");
                window.location = "/login";
            }
        });
    }
});

const client = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false
    }),
    link: from([errorLink, authMiddleware, httpLink])
});

export default client;
