export default {
    searchTxt: {
        en: "Search for Events and Horses by Name, State, Track, Owners, Trainers and Breeders",
        es: "Busqueda de Eventos y Caballos por Nombre, Estado, Hipodromo, Dueno, Entrenador y Criador"
    },
    searchBrn: {
        en: "Lets Go",
        es: "Listos!"
    },
    heroTitle: {
        en: "EXPERIENCE THE FUTURE AND EASE IN ENTRY MANAGEMENT",
        es: "VIVE EL FUTURO Y LA FACILIDAD DEL MANEJO DE LAS ENTRADAS"
    },
    browseBtnCaps: {
        en: "BROWSE EVENTS",
        es: "VISTAZO DE EVENTOS"
    },
    about: {
        en:
            "EZ Horse Entry was built to serve those in the equestrian sports field to better manage their experience. From horse owners to trainers, we offer a variety of tools and resources to help you save time and money. We strive to assist all horse sporting enthusiasts in establishing a fulfilling and successful business.",
        es:
            "EZ Horse Entry fue creado para servir aquellos en el deporte equino para mejorar la experiencia. De duenos a entrenadores, ofrecemos varias herramientas y recursos para ahorrar tiempo y dinero.  Nos esforzamos en asistir todo entusiasta del deporte equino con estableciendo un negocio exitoso."
    },
    playBtn: {
        en: "Where do you want to play today?",
        es: "Donde quieres jugar hoy?"
    },
    readyHdr: {
        en: "READY FOR ACTION",
        es: "LISTO PARA ACCIO'N"
    },
    readySubHdr: {
        en: "CHOOSE AN EVENT",
        es: "ESCOGE UN EVENTO"
    },
    browseBtn: {
        en: "Browse Events",
        es: "Vistazo a Eventos"
    },
    carousel: {
        learnBtn: {
            en: "Learn More",
            es: "Aprende Mas"
        },
        signupBtn: {
            en: "Sign Up",
            es: "Regístrate"
        },
        searchHdr: {
            en: "Search & Filter Events",
            es: "Buscar y Filtrar Eventos"
        },
        searchMsg: {
            en: "Searching and filtering for specific events and conditions made easy",
            es: "Busqueda y Filtrado de eventos Especificos y condiciones simplificados"
        },
        stableHdr: {
            en: "Digital Stable",
            es: "Establo Digital"
        },
        stableMsg: {
            en: "Upload and manage your stable online",
            es: "Suba y maneje su Establo en linea"
        },
        teamHdr: {
            en: "Create Teams",
            es: "Crear Equipos"
        },
        teamsMsg: {
            en: "Add your team members to keep everyone informed",
            es: "Agregue los miembros de su Equipo para tenerlos informados"
        },
        payHdr: {
            en: "Online Payments",
            es: "Pagos en Linea"
        },
        payMsg: {
            en: "Payments for nominations made easy for both parties",
            es: "Pagos para Nominaciones echo facil para ambas partes"
        },
        moreHdr: {
            en: "And Much More...",
            es: "Y Mucho Mas..."
        },
        moreMsg: {
            en: "Sign up today to experience everything that EZ Horse Entry offers.",
            es: "Registrate Hoy para experimentar todo lo que EZ Horse Entry Ofrece"
        }
    },
    info: {
        searchHdr: {
            en: "Search and Enter Events",
            es: "Buscar Y Ingresar Eventos"
        },
        searchDesc: {
            en:
                "Finding events has never been easier! Search races for free by location, type, and even your favorite race conditions. Registered users may enter into events all from your computer. No more paper entries or phone calls!",
            es:
                "Econtrar eventos nunca sido tan facil! Buscar carreras,locacion,tipo eh incluso condiciones de su carrera favorita gratis.Usuarios Registrados pueden entrar en eventos todo desde su computadora. No mas entradas en papel o llamadas de telefono!"
        },
        searchbtn: {
            en: "Search Events",
            es: "Busca Eventos"
        },
        stableHdr: {
            en: "Upload & Manage Stable",
            es: "Cargar & Administrar Establo"
        },
        stableDesc: {
            en:
                "Registered users have the ability to create their own online stables. There you can add, edit, and track all the important info and stats related to your horses such as winds, earnings, heritage, upcoming races, and more.",
            es:
                "Usuarios registrados tienen la abilidad de crear su propio Establo en linea. Ahi puede agregar,editar,registrar toda informacion importante y Estadisticas relacionadas con sus caballos tal como triunfios   ingresos, herencia y proximas carreras y mas."
        },
        signupBtn: {
            en: "Sign Up",
            es: "Regístrate"
        },
        teamHdr: {
            en: "Create & Message Teams",
            es: "Crear & Enviar Mensajes A Equipos"
        },
        teamDesc: {
            en:
                "Owners and Trainers can now stay in touch all through the app. Notifications will keep all members informed on the latest for all their horses.",
            es:
                "Duenos y Entrenadores pueden estar en contacto atraves del app Notificasiones tendran a todos los miembros informados en toda la actualidad de interes  de sus caballos."
        },
        muchHdr: {
            en: "And Much More....",
            es: "Y Mucho Mas..."
        },
        payment: {
            en: "Payment Reminders",
            es: "Registrate Hoy pra experimentar todo lo que EZ Horse Entry Ofrece"
        },
        finances: {
            en: "Track finances and see spending/earnings with chart views",
            es: "Seguimiento de finanzas y ver gastos/ganacias con el grafico de vistas."
        },
        reports: {
            en: "Run reports to check current status",
            es: "Ejecutar informes para checar situasion actual"
        },
        events: {
            en: "Track/Event owners can create and market events",
            es: "Propietarios de eventos y Hipodromos pueden crear mercdo /eventos."
        },
        documents: {
            en: "Upload event related documents (Piro, Coggins, etc)",
            es: "Subir eventos documentos relacionados (Piro,Coggins,ect)"
        },
        nominations: {
            en: "Track/Event owners can approve/deny nominations",
            es: "Propietarios de eventos y Hipodromos pueden appovar/negar nominaciones."
        },
        signupTodayBtn: {
            en: "Sign Up Today!",
            es: "Rejistrate Hoy!"
        }
    }
}
