import React, { useContext } from "react"
import { Route, Redirect } from "react-router-dom"
import AuthContext from "../authContext"
import Loading from "./loading"

function PrivateRoute({ children, type, admin = false, ...rest }) {
    const { user, isAuth } = useContext(AuthContext)
    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (isAuth && location.pathname === "/connect-success") {
                    return children
                } else if (isAuth && !user) {
                    return <Loading />
                } else if (isAuth && !type && admin && (user?.isAdmin || user?.isDataEntry)) {
                    return children
                } else if (isAuth && !type && !admin) {
                    return children
                } else if (isAuth && type && user?.userType === type) {
                    return children
                } else if (isAuth && type && user.userType !== type) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: location }
                            }}
                        />
                    )
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: location }
                            }}
                        />
                    )
                }
            }}
        />
    )
}

export default PrivateRoute
