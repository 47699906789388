import React from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import { Colors } from "../constants";
import Text from "./translation";
import TXT from "./footerTranslations";

const Header5 = styled.h5`
    color: white;
    font-size: 16px;
    text-transform: uppercase;
`;

const Div = styled.div`
    background-color: #212936;
    color: white;
    padding: 48px 0 4px 0;
`;

const Ul = styled.ul`
    list-style: none;
    padding: 0;
`;

const Links = styled.a`
    text-decoration: none;
    color: ${Colors.white};
`;

const BibleBanner = styled.span`
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 12px 16px;
    & span {
        display: block;
    }
`;

const Footer = () => {
    return (
        <Div>
            <Row>
                <Col span={18} offset={3}>
                    <Row type="flex" justify="space-around">
                        <Col span={6}>
                            <Header5>
                                <Text textObject={TXT.account} />
                            </Header5>
                            <Ul>
                                <Links href={"/login?new=t"}>
                                    <li>
                                        <Text textObject={TXT.register} />
                                    </li>
                                </Links>
                                <Links href={"/login"}>
                                    <li>
                                        <Text textObject={TXT.login} />
                                    </li>
                                </Links>
                                <Links href={"/events"}>
                                    <li>
                                        <Text textObject={TXT.events} />
                                    </li>
                                </Links>
                            </Ul>
                        </Col>

                        <Col span={6}>
                            <Header5>
                                <Text textObject={TXT.cs} />
                            </Header5>
                            <Ul>
                                <li>
                                    <Text textObject={TXT.faq} />
                                </li>
                                <li>
                                    <Text textObject={TXT.contact} />
                                </li>
                            </Ul>
                        </Col>
                        <Col span={6}>
                            <Header5>
                                <Text textObject={TXT.company} />
                            </Header5>
                            <Ul>
                                <li>
                                    <Text textObject={TXT.about} />
                                </li>
                                <li>
                                    <Text textObject={TXT.cookie} />
                                </li>
                                <li>
                                    <Text textObject={TXT.disclaimer} />
                                </li>
                                <li>
                                    <Text textObject={TXT.terms} />
                                </li>
                            </Ul>
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            &copy; {new Date().getFullYear()} EZ Horse Entry - <Text textObject={TXT.rights} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <BibleBanner home>
                <span>Romans 8:1</span>
                <span>Psalms 1:3</span>
                <span>Jermiah 19:11</span>
            </BibleBanner>
        </Div>
    );
};

export default Footer;
