export default {
    home: {
        en: "Home",
        es: "Inicio"
    },
    welcome: {
        en: "Welcome",
        es: "Bienvenido"
    },
    browseEvents: {
        en: "Browse Events",
        es: "Vistazo a Eventos"
    },
    login: {
        en: "Login",
        es: "Iniciar Sesión"
    },
    signup: {
        en: "Sign Up",
        es: "Regístrate"
    },
    myEvents: {
        en: "My Events",
        es: "Mis Eventos"
    },
    myStable: {
        en: "My Stable",
        es: "Mi Establo"
    },
    createEvent: {
        en: "Create Event",
        es: "Crear Evento"
    },
    teams: {
        en: "Manage Teams",
        es: "Manejar Equipos"
    },
    logOut: {
        en: "Log Out",
        es: "Cerrar sesion"
    },
    notifications: {
        en: "Notifications",
        es: "Notificaciones"
    },
    clearNotifications: {
        en: "Clear All Notifications",
        es: "Lipiar todas Notificaciones"
    },
    settings: {
        en: "Settings",
        es: "Los Ajustes"
    },
    dismiss: {
        en: "DISMISS",
        es: "DESCARTAR"
    },
    financial: {
        en: "Financial View",
        es: "Vista Financiera"
    }
}
