export default {
    account: {
        en: "Account",
        es: "Cuenta"
    },
    register: {
        en: "Register",
        es: "Registrarse"
    },
    events: {
        en: "Events",
        es: "Eventos"
    },
    login: {
        en: "Login",
        es: "Iniciar Sesión"
    },
    cs: {
        en: "Customer Support",
        es: "Atención al Cliente"
    },
    faq: {
        en: "FAQ",
        es: "Preguntas más Frecuentes"
    },
    contact: {
        en: "Contact",
        es: "Contacto"
    },
    company: {
        en: "Company",
        es: "Empresa"
    },
    about: {
        en: "About",
        es: "Acerca de Nosotros"
    },
    cookie: {
        en: "Cookie Policy",
        es: "Poliza Cookie"
    },
    disclaimer: {
        en: "Disclaimer",
        es: "Descargo de Responsabilidad"
    },
    terms: {
        en: "Terms and Conditions",
        es: "Terminos y Condiciones"
    },
    rights: {
        en: "All Rights Reserved",
        es: "Todos los Derechos Reservados"
    }
};
