import React, { lazy, Suspense, useState, useEffect } from "react"
import { ApolloProvider } from "@apollo/client"
import client from "./apolloClient"
import "antd/dist/antd.css"
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import { createGlobalStyle } from "styled-components"
import Nav from "./components/nav"
import AuthContext from "./authContext"
import PrivateRoute from "./components/privateRoute"
import LandingPage from "./components/landingPage"
import Footer from "./components/footer"
import Analytics from "react-router-ga"
import Loading from "./components/loading"
import LangContext from "./langContext"
import LanguageSelector from "./components/translation/languageSelector"

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Raleway', Helvetica, sans-serif ;
  }

  .mainDiv{
      height: 100vh;
  }
`

// All components being rendered as a different page/route should be imported like this to enable code splitting and faster initial loading speeds
const Login = lazy(() => import("./components/login/"))
const CreateEvent = lazy(() => import("./components/createEvent"))
const Events = lazy(() => import("./components/events"))
const Event = lazy(() => import("./components/event"))
const Stable = lazy(() => import("./components/stable"))
const MyEvents = lazy(() => import("./components/myEvents"))
const MyEvent = lazy(() => import("./components/myEvent"))
const Settings = lazy(() => import("./components/settings"))
const ConnectSuccess = lazy(() => import("./components/settings/connectSuccess"))
const NominationPayment = lazy(() => import("./components/stable/nominationPayment"))
const AdminDashboard = lazy(() => import("./components/admin-dashboard"))
const Teams = lazy(() => import("./components/teams"))
const TeamStable = lazy(() => import("./components/teams/teamStable"))
const Finance = lazy(() => import("./components/financials"))

const ProdAnalytics = ({ prod, children }) => {
    return prod ? <Analytics id={"UA-171155061-1"}>{children}</Analytics> : children
}

const acceptableLanguages = ["en", "es"]

const getBrowserLang = () => {
    let lang = window.navigator.languages ? window.navigator.languages[0] : null
    lang = lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage

    if (lang.indexOf("-") !== -1) lang = lang.split("-")[0]

    if (lang.indexOf("_") !== -1) lang = lang.split("_")[0]

    if (!lang || !acceptableLanguages.includes(lang)) return "en"
    return lang
}

function App() {
    const [token, setToken] = useState(localStorage.getItem("token") || "")
    const [user, setUser] = useState(null)
    const [isAuth, setIsAuth] = useState(!!token)
    const [loggingOut, setLoggingOut] = useState(false)
    const [lang, setLang] = useState(localStorage.getItem("lang") || "")
    const prod = process.env.NODE_ENV === "production"

    const logOut = () => {
        return new Promise(resolve => {
            localStorage.removeItem("token")
            setUser(null)
            setIsAuth(false)
            setToken("")
            resolve()
        })
    }

    useEffect(() => {
        if (!lang) {
            setLang(getBrowserLang())
        }
    }, [])

    return (
        <AuthContext.Provider
            value={{
                token: token,
                setToken: setToken,
                user: user,
                setUser: setUser,
                isAuth: isAuth,
                setIsAuth: setIsAuth,
                loggingOut: loggingOut,
                setLoggingOut: setLoggingOut,
                logOut: logOut
            }}
        >
            <LangContext.Provider value={{ lang: lang, setLang: setLang }}>
                <ApolloProvider client={client}>
                    <Router>
                        <ProdAnalytics prod={prod}>
                            <GlobalStyles />
                            <Nav />
                            <LanguageSelector />
                            <Suspense fallback={<Loading />}>
                                <Switch>
                                    <Route exact path={"/"}>
                                        <LandingPage />
                                    </Route>
                                    <Route path={"/login"}>
                                        <Login />
                                    </Route>
                                    <Route path={"/events"}>
                                        <Events />
                                    </Route>
                                    <Route path={"/event/:id"}>
                                        <Event />
                                    </Route>
                                    <Route path={"/search"}>
                                        <Events />
                                    </Route>
                                    <PrivateRoute path={"/dashboard"}>
                                        {user?.userType === "STANDARD" ? (
                                            <Redirect to={"/stable"} />
                                        ) : user?.userType === "EVENT_OWNER" ? (
                                            <Redirect to={"/myEvents"} />
                                        ) : (
                                            <Redirect to={"/dashboard"} />
                                        )}
                                    </PrivateRoute>
                                    <PrivateRoute path={"/settings/:tab?"}>
                                        <Settings />
                                    </PrivateRoute>
                                    <PrivateRoute type={"EVENT_OWNER"} path={"/createEvent"}>
                                        <CreateEvent />
                                    </PrivateRoute>
                                    <PrivateRoute type={"STANDARD"} path={"/stable"}>
                                        <Stable />
                                    </PrivateRoute>
                                    <PrivateRoute type={"EVENT_OWNER"} path={"/myEvents"}>
                                        <MyEvents />
                                    </PrivateRoute>
                                    <PrivateRoute type={"EVENT_OWNER"} path={"/myEvent/:id"}>
                                        <MyEvent />
                                    </PrivateRoute>
                                    <PrivateRoute type={"EVENT_OWNER"} path={"/connect-success"}>
                                        <ConnectSuccess />
                                    </PrivateRoute>
                                    <PrivateRoute type={"STANDARD"} path={"/nomination-pay/:eventId/:horseId"}>
                                        <NominationPayment />
                                    </PrivateRoute>
                                    <PrivateRoute type={"STANDARD"} exact path={"/teams"}>
                                        <Teams />
                                    </PrivateRoute>
                                    <PrivateRoute type={"STANDARD"} path={"/teams/stable/:teamId"}>
                                        <TeamStable />
                                    </PrivateRoute>
                                    <PrivateRoute type={"STANDARD"} path={"/finance"}>
                                        <Finance />
                                    </PrivateRoute>
                                    <PrivateRoute admin={true} path={"/admin-dashboard"}>
                                        <AdminDashboard />
                                    </PrivateRoute>
                                    <Route path={"*"}>
                                        <Redirect to={"/"} />
                                    </Route>
                                </Switch>
                            </Suspense>
                            <Footer />
                        </ProdAnalytics>
                    </Router>
                </ApolloProvider>
            </LangContext.Provider>
        </AuthContext.Provider>
    )
}

export default App
