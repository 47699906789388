import React, { useContext, useState } from "react"
import styled from "styled-components"
import LangContext from "../../langContext"
import { Radio, Icon } from "antd"
import Text from "./index"
import { Colors } from "../../constants"

const TXT = {
    choose: {
        es: "Language",
        en: "Idioma"
    }
}

const LangCntr = styled.div`
    padding: 14px;
    position: fixed;
    bottom: 8px;
    right: 8px;
    height: ${props => (props.open ? "80px" : "52px")};
    width: ${props => (props.open ? "192px" : "112px")};
    background-color: ${props => (props.open ? Colors.white : Colors.lightPrimaryOrange)};
    z-index: 10000;
    border-radius: 25px;
    box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.5);
    transition: box-shadow 300ms ease-in-out, height 100ms ease-in-out, width 100ms ease-in-out;
    &:hover {
        box-shadow: 2px 4px 18px 1px rgba(0, 0, 0, 0.5);
    }
    @media (max-width: 682px) {
        margin: 0;
    }
    & > h4 {
        margin: 0;
        color: ${props => (props.open ? Colors.darkGray : Colors.white)};
        text-align: center;
        cursor: pointer;
        z-index: 10;
    }
    & label.ant-radio-button-wrapper:hover {
        color: ${Colors.primaryOrange};
    }
    & label.ant-radio-button-wrapper-checked.ant-radio-button-wrapper {
        background-color: ${Colors.primaryOrange};
        border-color: ${Colors.primaryOrange};
        &:hover {
            background-color: ${Colors.lightPrimaryOrange};
            border-color: ${Colors.lightPrimaryOrange};
            color: ${Colors.white};
        }
    }
`

const RadioVert = styled(Radio)`
    display: block;
    height: 24px;
    line-height: 24px;
`

const RadioGroupDropDown = styled(Radio.Group)`
    opacity: ${props => (props.show ? "100%" : "0%")};
    transition: opacity 200ms ease-in-out;
`

const handleLanguageChange = setLang => e => {
    const lang = e.target.value
    setLang(lang)
    localStorage.setItem("lang", lang)
}

const handleFadeOut = (setShowLang, setFadeIn) => {
    setTimeout(() => {
        setFadeIn(false)
        setTimeout(() => {
            setShowLang(false)
        }, 300)
    }, 100)
}

const handleFadeIn = (setShowLang, setFadeIn) => {
    setTimeout(() => {
        setShowLang(true)
        setTimeout(() => {
            setFadeIn(true)
        }, 50)
    }, 50)
}

const LanguageSelector = () => {
    const { lang, setLang } = useContext(LangContext)
    const [showLang, setShowLang] = useState(false)
    const [fadeIn, setFadeIn] = useState(false)

    return (
        <LangCntr
            open={showLang}
            onMouseOver={() => handleFadeIn(setShowLang, setFadeIn)}
            onMouseLeave={() => handleFadeOut(setShowLang, setFadeIn)}
        >
            <h4>
                <Icon type="global" /> <Text textObject={TXT.choose} />
            </h4>
            {showLang && (
                <RadioGroupDropDown
                    onChange={handleLanguageChange(setLang)}
                    value={lang}
                    buttonStyle={"solid"}
                    show={fadeIn}
                >
                    <RadioVert.Button value={"en"}>English</RadioVert.Button>
                    <RadioVert.Button value={"es"}>Español</RadioVert.Button>
                </RadioGroupDropDown>
            )}
        </LangCntr>
    )
}

export default LanguageSelector
