export const Colors = {
    white: "#ffffff",
    primaryOrange: "#ED563C",
    lightPrimaryOrange: "#ff6043",
    veryLightPrimaryOrange: "rgba(255,105,71,0.61)",
    primaryBrandGold: "#D1A51A",
    secondaryBlue: "#202836",
    darkGray: "#2e2e2e",
    medGray: "#6e6e6e",
    medLightGray: "#939393",
    lightGray: "#dadada",
    sideBarGray: "#e8e8e8",
    veryLighGray: "#F2F4F5",
    gray: "#F2F4F5",
    yellow: "#ffd640",
    red: "#f30000",
    darkRed: "#bf0000",
    green: "#008118",
    chekckGreen: "#33BA45",
    blackTint70: "rgba(0,0,0,0.70)",
    blackTint50: "rgba(0,0,0,0.50)",
    blackTint30: "rgba(0,0,0,0.30)"
};

export const racingFilters = {
    horseAge: {
        title: {
            en: "Age",
            es: "Edad"
        },
        filters: [
            { val: "TWO", dis: "2" },
            { val: "THREE", dis: "3" },
            { val: "THREE_PLUS", dis: "3+" },
            { val: "THREE_FOUR_FIVE", dis: "3, 4, 5" }
        ]
    },
    horseType: {
        title: {
            en: "Horse Type",
            es: "Tipo de Caballo"
        },
        filters: [
            { val: "QUARTER", dis: "Quarter Horse" },
            { val: "THOROUGHBRED", dis: "Thoroughbred" },
            { val: "ARABIAN", dis: "Arabian" },
            { val: "APPALOOSA", dis: "Appaloosa" },
            { val: "PAINT", dis: "Paint" },
            { val: "PAINT_APPALOOSA", dis: "Paints & Appaloosas" }
        ]
    },
    gender: {
        title: {
            en: "Gender",
            es: "Género"
        },
        filters: [
            { val: "OPEN", dis: "Open" },
            { val: "COLT", dis: "Colt" },
            { val: "GELDING", dis: "Gelding" },
            { val: "FILLY", dis: "Filly" },
            { val: "MARE", dis: "Mare" },
            { val: "FILLY_MARE", dis: "Fillies & Mares" }
        ]
    },
    grading: {
        title: {
            en: "Grading",
            es: "Calificación"
        },
        filters: [{ val: "ONE", dis: "G1" }, { val: "TWO", dis: "G2" }, { val: "THREE", dis: "G3" }]
    },
    raceTypes: {
        title: {
            en: "Race Type",
            es: "Tipo de Carrera"
        },
        filters: [
            { val: "OPEN", dis: "Open" },
            { val: "STATE_BRED", dis: "State Bred" },
            { val: "MAIDEN", dis: "Maiden" },
            { val: "CLAIM", dis: "Claim" },
            { val: "OPTIONAL", dis: "Optional Claim" },
            { val: "STAKES", dis: "Stakes" },
            { val: "ALLOWANCE", dis: "Allowance" }
        ]
    },
    surface: {
        title: {
            en: "Surface Type",
            es: "Tipo de Superficie"
        },
        filters: [{ val: "TURF", dis: "Turf" }, { val: "DIRT", dis: "Dirt" }, { val: "SYNTHETIC", dis: "Synthetic" }]
    }
};

export const distanceFilters = {
    title: {
        en: "Distance",
        es: "Distancia"
    },
    filters: [
        { val: "MILES", dis: "Miles", options: ["1", "1 1/16", "1 1/8", "1 1/4", "1 3/8", "1 1/2"] },
        {
            val: "YARDS",
            dis: "Yards",
            options: ["50", "1 mile & 70", "75", "110", "220", "250", "300", "330", "350", "375", "400", "440", "550", "660", "770", "870"]
        },
        {
            val: "FURLONGS",
            dis: "Furlongs",
            options: [
                "4",
                "4 1/2",
                "5",
                "5 1/2",
                "6",
                "6 1/2",
                "7",
                "7 1/2",
                "8",
                "8 1/2",
                "9",
                "9 1/2",
                "10",
                "10 1/2",
                "11",
                "11 1/2",
                "12",
                "12 1/2"
            ]
        },
        { val: "KILOMETERS", dis: "Kilometers", options: ["1.0", "1.2", "1.4", "1.6", "1.8", "2.0", "2.2", "2.4"] }
    ]
};
